import { Injectable } from '@angular/core'
import { Observable, BehaviorSubject } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { environment } from '@masterdata/env/environment'
import { map, retry } from 'rxjs/operators'
import { UserDto } from '@agroone/entities'
import { LoggerService } from '@agroone-front/shared'

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userSubject: BehaviorSubject<UserDto> = new BehaviorSubject<UserDto>(null)
  public user: Observable<UserDto>

  get currentUser(): UserDto {
    return this.userSubject.getValue()
  }

  constructor(private http: HttpClient, private logger: LoggerService) {
    this.user = this.userSubject.asObservable()
  }

  public init(): Observable<UserDto> {
    return this.getCurrentUser()
  }

  private getCurrentUser(): Observable<UserDto> {
    return this.http.get<UserDto>(`${environment.baseUrl}/users/me`).pipe(
      retry(1),
      map((user: UserDto) => {
        user.availableRegions.sort((x, y) => x.localeCompare(y))
        const newUser = new UserDto(user)
        if (user) {
          this.userSubject.next(newUser)
          this.logger.log('User Initialized')
        }
        return newUser
      })
    )
  }
}
