import 'reflect-metadata'

import { plainToInstance, Type } from 'class-transformer'
import {
  IsArray,
  IsDateString,
  IsIn,
  IsInt,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  Matches,
  Min,
  Validate,
  ValidateNested,
} from 'class-validator'
import { IsISO8601WithTimezone, RequireProperty } from '@agroone/helpers'

export class SavePesticideIngredient {
  @IsString()
  @IsNotEmpty()
  name: string | undefined

  @IsNumber()
  @Min(0)
  concentration: number | undefined

  @IsString()
  @IsNotEmpty()
  concentrationUnit: string | undefined
}

export class SaveReentry {
  @IsNumber()
  @RequireProperty('reentryUnit')
  @Min(0)
  reentryInterval: number | undefined

  @IsString()
  @RequireProperty('reentryInterval')
  @IsNotEmpty()
  reentryUnit: string | undefined

  @IsOptional()
  @IsNumber()
  @Min(0)
  interventionMinValue?: number

  @IsOptional()
  @IsString()
  interventionMinUnit?: string

  @IsOptional()
  @IsNumber()
  @Min(0)
  applicationNumber?: number

  @IsOptional()
  @IsString()
  fieldType?: string
}

export class SavePesticideTargetCropType {
  @IsNumber()
  @IsNotEmpty()
  cropTypeId: number | undefined

  @IsString()
  @RequireProperty('preharvestIntervalUnit')
  preharvestInterval: string | undefined

  @IsString()
  @RequireProperty('preharvestIntervalUnit')
  preharvestIntervalMax?: string

  @IsString()
  @RequireProperty('preharvestInterval')
  @IsNotEmpty()
  preharvestIntervalUnit: string | undefined

  @IsNumber()
  @RequireProperty('doseUnit')
  @Min(0)
  maxDose: number | undefined

  @IsNumber()
  @IsInt()
  @IsOptional()
  passageNumber?: number

  @IsNumber()
  @IsOptional()
  cumulative?: number

  @IsString()
  @RequireProperty('maxDose')
  @IsNotEmpty()
  // @toimprove DoseUnit not tested if it exists for now
  doseUnit: string | undefined

  @IsOptional()
  @IsDateString()
  lastUse?: string

  @IsOptional()
  @IsDateString()
  registrationExpiration?: string

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => SaveReentry)
  reentries: SaveReentry[] | undefined
}

export class SavePesticide {
  public static build(raw: any) {
    return plainToInstance(SavePesticide, raw)
  }

  @IsNumber()
  @IsOptional()
  id?: number

  @IsString()
  @IsNotEmpty()
  name: string | undefined

  @IsString()
  @IsNotEmpty()
  manufacturer: string | undefined

  @IsString()
  @IsNotEmpty()
  identificationNumber: string | undefined

  @IsNumber()
  @IsNotEmpty()
  countryId: number | undefined

  @IsString()
  @IsNotEmpty()
  pesticideType: string | undefined

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => SavePesticideIngredient)
  activeIngredients: SavePesticideIngredient[] | undefined

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => SavePesticideTargetCropType)
  targetCrops: SavePesticideTargetCropType[] | undefined
}
