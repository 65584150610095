import 'reflect-metadata'

import { plainToInstance, Type } from 'class-transformer'
import {
  IsBoolean,
  IsEnum,
  IsInt,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsPositive,
  IsString,
  Max,
  MaxLength,
  Min,
  Validate,
  ValidateNested,
} from 'class-validator'

import { IsDecimalNumber, IsISO8601WithTimezone } from '@agroone/helpers'

import { SeverityEnum } from '../enums/severity.enum'
import { SaveFeatureCollection } from './save-feature-collection'
import { SaveLocation } from './save-location'
import { SaveReporting } from './save-reporting'

export class SaveFieldObstruction {
  @IsNumber()
  @IsOptional()
  id?: number

  @IsString()
  @IsNotEmpty()
  name: string

  @IsEnum(SeverityEnum)
  @IsNotEmpty()
  severity: SeverityEnum

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveLocation)
  location?: SaveLocation

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveFeatureCollection)
  boundary?: SaveFeatureCollection
}

export class SaveField extends SaveReporting {
  public static override build(raw: any) {
    return plainToInstance(SaveField, raw)
  }

  @IsNumber()
  @IsOptional()
  id?: number

  @IsString()
  @IsNotEmpty()
  @MaxLength(90)
  name: string

  @IsNotEmpty()
  @IsNumber()
  @Validate(IsDecimalNumber, [4])
  size: number

  @IsString()
  @IsNotEmpty()
  unit: string

  @IsNumber()
  @IsNotEmpty()
  regionId: number

  @IsNumber()
  @IsNotEmpty()
  farmId: number

  @IsNumber()
  @IsNotEmpty()
  growerId: number

  @IsNumber()
  @IsNotEmpty()
  countryId: number

  @IsNumber()
  @IsOptional()
  country?: string

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveLocation)
  location: SaveLocation

  @IsBoolean()
  @IsOptional()
  active?: boolean

  @IsString()
  @IsOptional()
  @MaxLength(250)
  town?: string

  @IsString()
  @IsOptional()
  @MaxLength(45)
  roadName?: string

  @IsString()
  @IsOptional()
  @MaxLength(45)
  civicNumber?: string

  @IsString()
  @IsOptional()
  notes?: string

  @IsString()
  @IsOptional()
  @MaxLength(45)
  soilTexture?: string

  @IsNumber()
  @Min(0)
  @Max(14)
  @Validate(IsDecimalNumber, [2])
  @IsOptional()
  pH?: number

  @IsNumber()
  @IsOptional()
  @Validate(IsDecimalNumber, [1])
  @Max(100)
  organicMatter?: number

  @IsNumber()
  @IsOptional()
  @Validate(IsDecimalNumber, [1])
  @Max(100)
  clayPercentage?: number

  @IsString()
  @IsOptional()
  @MaxLength(45)
  drainage?: string

  @IsString()
  @IsOptional()
  @MaxLength(45)
  drainageType?: string

  @IsString()
  @IsOptional()
  @MaxLength(45)
  drainageClassification?: string

  @IsString()
  @IsOptional()
  @MaxLength(250)
  topography?: string

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveFieldObstruction)
  obstructions?: SaveFieldObstruction[]

  @IsInt()
  @Min(0)
  @Max(100)
  @IsOptional()
  slopePercentage?: number

  @IsString()
  @IsOptional()
  @MaxLength(45)
  stonePressure?: string

  @IsString()
  @IsOptional()
  @MaxLength(45)
  drainageWidth?: string

  @IsBoolean()
  @IsNotEmpty()
  organic: boolean

  @IsOptional()
  @Validate(IsISO8601WithTimezone)
  lastSoilAnalysisDate?: string

  @IsBoolean()
  @IsNotEmpty()
  irrigable: boolean

  @IsString()
  @IsOptional()
  @MaxLength(45)
  equipment?: string

  @IsString()
  @IsOptional()
  @MaxLength(45)
  fieldType?: string

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveFeatureCollection)
  boundary?: SaveFeatureCollection

  @IsString()
  @IsOptional()
  userId?: string

  @IsString()
  @IsOptional()
  @MaxLength(45)
  externalId?: string

  @IsInt()
  @IsPositive()
  @IsOptional()
  externalClientId?: number

  @IsString()
  @IsOptional()
  @MaxLength(45)
  certificationOrganicNumber?: string

  @IsOptional()
  @Validate(IsISO8601WithTimezone)
  certificationStartDate?: string

  @IsOptional()
  @Validate(IsISO8601WithTimezone)
  certificationEndDate?: string

  @IsString()
  @IsOptional()
  @MaxLength(45)
  waterSource?: string

  @IsString()
  @IsOptional()
  @MaxLength(45)
  surfaceWaterName?: string

  @IsString()
  @IsOptional()
  @MaxLength(45)
  hillsideReservoirName?: string

  @IsString()
  @IsNotEmpty()
  region: string
}
