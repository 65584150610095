import { ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator'

@ValidatorConstraint({ name: 'isISO8601WithTimezone', async: false })
export class IsISO8601WithTimezone implements ValidatorConstraintInterface {
  /**
   * Matches the format ISO8601 but with no milliseconds and mandatory timezone
   * i.e: 2019-09-03T23:45:23-06:00
   * @param dateValue
   */
  validate(dateValue: string) {
    return /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d)$/.test(dateValue)
  }

  defaultMessage() {
    // here you can provide default error message if validation failed
    return 'Date must be ISO8601 with exactly this format YYYY-MM-DDThh:mm:ssTZD (eg 2012-03-29T10:05:45-06:00)'
  }
}
