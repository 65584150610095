import {
  AuthService,
  ErrorInterceptor,
  LoggerService,
  LoginComponent,
  MonitoringService,
  SharedLogoutComponent,
  WithCredentialsInterceptor,
} from '@agroone-front/shared'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from '@angular/core'
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouteReuseStrategy } from '@angular/router'
import { environment } from '@masterdata/env/environment'
import {
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
} from '@nebular/theme'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { firstValueFrom } from 'rxjs'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { AppInjector } from './core/app-injector'
import { CoreModule } from './core/core.module'
import { GlobalErrorHandler } from './core/message/services/error-handler.service'
import { UserService } from './core/user/user.service'
import { MdRouteReuseStrategy } from './shared/main-filter/md-route-reuse-strategy'
import { RegionService } from './shared/region/region.service'
import { ThemeModule } from './theme/theme.module'
import { TranslationsLoader } from './translations.loader'

export const init =
  (userService: UserService, regionService: RegionService, loggerService: LoggerService, authService: AuthService) =>
  async () => {
    try {
      // todo: remove the initialization lines below after migrating them to libs/
      loggerService.init(environment.logLevel, environment.serverLogLevel, environment.isLocal)
      authService.init(environment.cognitoDomain, environment.cognitoClientId, environment.baseUrl)

      // Init User
      await firstValueFrom(userService.init())

      await Promise.all([regionService.init()])
    } catch (error) {}
  }

@NgModule({
  declarations: [AppComponent],
  imports: [
    LoginComponent,
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationsLoader,
      },
    }),
    SharedLogoutComponent,
    HttpClientModule,
    AppRoutingModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    MatProgressSpinnerModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: init,
      multi: true,
      deps: [UserService, RegionService, LoggerService, AuthService],
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WithCredentialsInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: MdRouteReuseStrategy,
    },
    MonitoringService,
    LoggerService,
    AuthService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    // Store module's injector in the AppInjector class
    AppInjector.setInjector(injector)
  }
}
