import 'reflect-metadata'

export class PesticideIngredient {
  id?: number
  name: string | undefined
  concentration: number | undefined
  concentrationUnit: string | undefined
}

export class PesticideProductLite {
  id: number | undefined
  pesticideType: string
  identificationNumber: string
  name: string

  constructor(pesticideLite?: PesticideProductLite) {
    this.id = pesticideLite?.id
    this.pesticideType = pesticideLite?.pesticideType ?? ''
    this.name = pesticideLite?.name ?? ''
    this.identificationNumber = pesticideLite?.identificationNumber ?? ''
  }
}

export class PesticideProduct {
  id: number | undefined // The unique ID within Relational Database
  pesticideType?: string
  name: string
  identificationNumber: string // The identificationNumber of the product
  doseUnit: string
  maxDose: number | undefined
  preharvestIntervalMax: string
  cumulative: number
  passageNumber: number
  lastUse: string | null
  isLinkedToSpec: boolean | null

  constructor(pesticideProduct?: PesticideProduct) {
    this.id = pesticideProduct?.id
    this.pesticideType = pesticideProduct?.pesticideType
    this.name = pesticideProduct?.name ?? ''
    this.identificationNumber = pesticideProduct?.identificationNumber ?? ''
    this.doseUnit = pesticideProduct?.doseUnit ?? ''
    this.maxDose = pesticideProduct?.maxDose
    this.preharvestIntervalMax = pesticideProduct?.preharvestIntervalMax ?? ''
    this.cumulative = pesticideProduct?.cumulative
    this.passageNumber = pesticideProduct?.passageNumber
    this.lastUse = pesticideProduct?.lastUse
    this.isLinkedToSpec = pesticideProduct?.isLinkedToSpec
  }
}

export class Reentry {
  id: number | undefined
  reentryInterval: number | undefined
  reentryUnit: string | undefined
  interventionMinValue?: number
  interventionMinUnit?: string
  applicationNumber?: number
  tunnel?: 'OUTDOOR' | 'GLASS HOUSE'
}

export class PesticideTargetCropType {
  id: number | undefined
  cropType: string | undefined
  preharvestInterval: string | undefined
  preharvestIntervalMax?: string | undefined
  preharvestIntervalUnit: string | undefined
  maxDose: number | undefined
  doseUnit: string | undefined
  lastUse?: string
  registrationExpiration?: string
  reentries: Reentry[] | undefined
}

export class Pesticide {
  id: number | undefined // The unique ID within Relational Database
  name: string | undefined
  manufacturer: string | undefined
  identificationNumber: string | undefined
  country: string | undefined
  pesticideType: string | undefined
  targetCrops: PesticideTargetCropType[] | undefined
  activeIngredients: PesticideIngredient[] | undefined
}

export class PesticideDAO {
  id: number | undefined
  pesticideType: string | undefined
  pesticideProductId: number | undefined
  productId: number | undefined
  productName: string | undefined
  manufacturer: string | undefined
  identificationNumber: string | undefined
  ingredientId: number | undefined
  ingredientName: string | undefined
  concentration: number | undefined
  concentrationUnit: string | undefined
  countryName: string | undefined
  phraseId: number | undefined
  phrase: string | undefined
  targetCropId: number | undefined
  targetCropTypeId: number | undefined
  croptypeKey: string | undefined
  preharvestInterval: string | undefined
  preharvestIntervalMax?: string
  preharvestIntervalUnit: string | undefined
  maxDose: number | undefined
  doseUnit: string | undefined
  lastUse?: string | undefined
  registrationExpiration?: string
  reentryId: number | undefined
  reentryInterval: number | undefined
  reentryUnit: string | undefined
  interventionMinValue: number | undefined
  interventionMinUnit: string | undefined
  applicationNumber: number | undefined
  fieldType: string | undefined
}

export class SpecificationPesticideDto {
  specificationId: number
  pesticideProductId: number
  cropTypeId: number
  dosage: number
  cumulative?: number
  phi: number | null
  passageNumber?: number
  note?: string

  constructor(data?: any) {
    this.specificationId = data?.specificationId
    this.pesticideProductId = data?.pesticideProductId
    this.cropTypeId = data?.cropTypeId
    this.dosage = data?.dosage != null ? Number(data.dosage) : null
    this.cumulative = data?.cumulative != null ? Number(data.cumulative) : null
    this.phi = data?.phi != null ? Number(data?.phi) : null
    this.passageNumber = data?.passageNumber != null ? Number(data.passageNumber) : null
    this.note = data?.note ?? ''
  }
}

export class PesticideProductLiteTable extends PesticideProductLite {
  unity: string
  dosage?: number
  cumulative?: string
  phi: string
  passageNumber?: number

  constructor(product?: PesticideProductLiteTable) {
    super(product)
    this.unity = product?.unity ?? ''
    this.dosage = product?.dosage
    this.cumulative = product?.cumulative ?? ''
    this.phi = product?.phi ?? ''
    this.passageNumber = product?.passageNumber
  }
}

export class PesticidesProductDAO {
  pesticideProductId: number
  rate: number
  rateUnit: string
  rateAreaUnit: string
  targetDate: string
  area: number
  alternativeSolution?: string
  note?: string
}
