import { plainToInstance, Type } from 'class-transformer'
import {
  ArrayNotEmpty,
  IsArray,
  IsBoolean,
  IsEnum,
  IsInt,
  IsNotEmpty,
  IsNumber,
  IsObject,
  IsOptional,
  IsString,
  Max,
  Min,
  Validate,
  ValidateNested,
} from 'class-validator'

import { IsDecimalNumber, RequireProperty } from '@agroone/helpers'
import { LevelsTrainingRegenerative } from '../enums/level-training.enum'
import { AreaUnit } from '../enums/area-unit.enum'
import { GrowerCsrFileType } from '../enums/grower-csr-type-file.enum'

export class GrowerCsrRequest {
  id: number
  growerId: number
  levelRegenerativeAgriculture: LevelsTrainingRegenerative
  totalFarmArea: number
  totalFarmAreaUnit: AreaUnit
  percentageProtectionZone: number
  note: string
  csrCertificationIds: string
  csrCertificationNames: string
  growerCsrFileIds: string
  growerCsrFilePaths: string
  growerCsrFileTypes: string
}

export class GrowerCsr {
  id: number
  growerId: number
  levelRegenerativeAgriculture: LevelsTrainingRegenerative
  totalFarmArea: number
  totalFarmAreaUnit: AreaUnit
  percentageProtectionZone: number
  certifications: GrowerCsrCertification[]
  files: GrowerCsrFile[]
  note: string
  growerCsrCampaign?: GrowerCsrCampaign
  isLocked?: boolean

  constructor(growerCsr?: GrowerCsr) {
    this.id = growerCsr?.id ?? null
    this.growerId = growerCsr?.growerId ?? null
    this.levelRegenerativeAgriculture = growerCsr?.levelRegenerativeAgriculture ?? null
    this.totalFarmArea = growerCsr?.totalFarmArea ?? null
    this.totalFarmAreaUnit = growerCsr?.totalFarmAreaUnit ?? null
    this.percentageProtectionZone = growerCsr?.percentageProtectionZone ?? null
    this.certifications = growerCsr?.certifications ?? []
    this.files = growerCsr?.files ?? []
    this.note = growerCsr?.note ?? null
    this.growerCsrCampaign = growerCsr?.growerCsrCampaign ?? null
    this.isLocked = growerCsr?.isLocked ?? true
  }
}

export class GrowerCsrCertification {
  id: number
  name: string
}

export class GrowerCsrFile {
  id: number
  name: string
  path: string
  presignedUrl: string
  type: GrowerCsrFileType
}

export class GrowerCsrFileDto {
  date: string
  name: string
  content: File
  type: GrowerCsrFileType
}

export class GrowerCsrCampaignRequest {
  id: number
  growerCsrId: number
  campaignId: number
  firstYearWithBonduelle: number
  speciesLastYear: number
  soilAreaLimitingDisturbance: number
  soilAreaLimitingDisturbanceUnit: string
  numberWeeksSoilCovered: number
  percentageSemiNaturalHabitat: number
  pollinatorDefensePlan: boolean
  spadeTest: boolean
  vessTest: boolean
  humicAssessment: boolean
  soilAnalysis: boolean
  csrSpadeTestFieldIds: string
  csrSpadeTestFieldNames: string
  csrVessTestFieldIds: string
  csrVessTestFieldNames: string
  csrHumicAssessmentFieldIds: string
  csrHumicAssessmentFieldNames: string
  csrSoilAnalysisFieldIds: string
  csrSoilAnalysisFieldNames: string
}

export class GrowerCsrCampaign {
  id: number
  campaignId: number
  growerCsrId: number
  firstYearWithBonduelle: number
  speciesLastYear: number
  soilAreaLimitingDisturbance: number
  soilAreaLimitingDisturbanceUnit: string
  numberWeeksSoilCovered: number
  percentageSemiNaturalHabitat: number
  pollinatorDefensePlan: boolean
  spadeTest: boolean
  vessTest: boolean
  humicAssessment: boolean
  soilAnalysis: boolean
  spadeTestFieldIds: number[]
  vessTestFieldIds: number[]
  humicAssessmentFieldIds: number[]
  soilAnalysisFieldIds: number[]

  constructor(growerCsrCampaign?: GrowerCsrCampaign) {
    this.id = growerCsrCampaign?.id ?? null
    this.campaignId = growerCsrCampaign?.campaignId ?? null
    this.growerCsrId = growerCsrCampaign?.growerCsrId ?? null
    this.firstYearWithBonduelle = growerCsrCampaign?.firstYearWithBonduelle ?? null
    this.speciesLastYear = growerCsrCampaign?.speciesLastYear ?? null
    this.soilAreaLimitingDisturbance = growerCsrCampaign?.soilAreaLimitingDisturbance ?? null
    this.soilAreaLimitingDisturbanceUnit = growerCsrCampaign?.soilAreaLimitingDisturbanceUnit ?? null
    this.numberWeeksSoilCovered = growerCsrCampaign?.numberWeeksSoilCovered ?? null
    this.percentageSemiNaturalHabitat = growerCsrCampaign?.percentageSemiNaturalHabitat ?? null
    this.pollinatorDefensePlan = growerCsrCampaign?.pollinatorDefensePlan ?? null
    this.spadeTest = growerCsrCampaign?.spadeTest ?? null
    this.vessTest = growerCsrCampaign?.vessTest ?? null
    this.humicAssessment = growerCsrCampaign?.humicAssessment ?? null
    this.soilAnalysis = growerCsrCampaign?.soilAnalysis ?? null
    this.spadeTestFieldIds = growerCsrCampaign?.spadeTestFieldIds ?? []
    this.vessTestFieldIds = growerCsrCampaign?.vessTestFieldIds ?? []
    this.humicAssessmentFieldIds = growerCsrCampaign?.humicAssessmentFieldIds ?? []
    this.soilAnalysisFieldIds = growerCsrCampaign?.soilAnalysisFieldIds ?? []
  }
}

export class SaveGrowerCsrCampaign {
  public static build(raw: any) {
    return plainToInstance(GrowerCsrCampaign, raw)
  }

  @IsNumber()
  @IsOptional()
  id?: number

  @IsNumber()
  @IsOptional()
  firstYearWithBonduelle?: number

  @IsNumber()
  @Min(1)
  @IsOptional()
  speciesLastYear?: number

  @IsNumber()
  @IsOptional()
  @Validate(IsDecimalNumber, [2])
  @RequireProperty('soilAreaLimitingDisturbanceUnit')
  soilAreaLimitingDisturbance?: number

  @IsString()
  @IsEnum(AreaUnit)
  @IsOptional()
  @RequireProperty()
  soilAreaLimitingDisturbanceUnit?: AreaUnit

  @IsNumber()
  @Min(0)
  @Max(53)
  @IsOptional()
  numberWeeksSoilCovered?: number

  @IsNumber()
  @Min(0)
  @Max(100)
  @IsOptional()
  percentageSemiNaturalHabitat?: number

  @IsBoolean()
  @IsOptional()
  pollinatorDefensePlan?: boolean

  @IsBoolean()
  @IsOptional()
  spadeTest?: boolean

  @IsBoolean()
  @IsOptional()
  vessTest?: boolean

  @IsBoolean()
  @IsOptional()
  humicAssessment?: boolean

  @IsBoolean()
  @IsOptional()
  soilAnalysis?: boolean

  @IsArray()
  @IsNumber({}, { each: true })
  @IsOptional()
  spadeTestFieldIds?: number[]

  @IsArray()
  @IsNumber({}, { each: true })
  @IsOptional()
  vessTestFieldIds?: number[]

  @IsArray()
  @IsNumber({}, { each: true })
  @IsOptional()
  humicAssessmentFieldIds?: number[]

  @IsArray()
  @IsNumber({}, { each: true })
  @IsOptional()
  soilAnalysisFieldIds?: number[]

  constructor(growerCsrCampaign?: SaveGrowerCsrCampaign) {
    if (growerCsrCampaign?.id) {
      this.id = growerCsrCampaign?.id
    }
    this.firstYearWithBonduelle = growerCsrCampaign?.firstYearWithBonduelle ?? null
    this.speciesLastYear = growerCsrCampaign?.speciesLastYear ?? null
    this.soilAreaLimitingDisturbance = growerCsrCampaign?.soilAreaLimitingDisturbance ?? null
    this.soilAreaLimitingDisturbanceUnit = growerCsrCampaign?.soilAreaLimitingDisturbanceUnit ?? null
    this.numberWeeksSoilCovered = growerCsrCampaign?.numberWeeksSoilCovered ?? null
    this.percentageSemiNaturalHabitat = growerCsrCampaign?.percentageSemiNaturalHabitat ?? null
    this.pollinatorDefensePlan = growerCsrCampaign?.pollinatorDefensePlan ?? false
    this.spadeTest = growerCsrCampaign?.spadeTest ?? false
    this.vessTest = growerCsrCampaign?.vessTest ?? false
    this.humicAssessment = growerCsrCampaign?.humicAssessment ?? false
    this.soilAnalysis = growerCsrCampaign?.soilAnalysis ?? false
    this.spadeTestFieldIds = growerCsrCampaign?.spadeTestFieldIds ?? []
    this.vessTestFieldIds = growerCsrCampaign?.vessTestFieldIds ?? []
    this.humicAssessmentFieldIds = growerCsrCampaign?.humicAssessmentFieldIds ?? []
    this.soilAnalysisFieldIds = growerCsrCampaign?.soilAnalysisFieldIds ?? []
  }
}
export class SaveGrowerCsr {
  public static build(raw: any) {
    return plainToInstance(SaveGrowerCsr, raw)
  }

  @IsNumber()
  @IsOptional()
  id?: number

  @IsNumber()
  @IsOptional()
  growerId?: number

  @IsString()
  @IsEnum(LevelsTrainingRegenerative)
  @IsNotEmpty()
  levelRegenerativeAgriculture: LevelsTrainingRegenerative

  @IsNumber()
  @IsNotEmpty()
  @Validate(IsDecimalNumber, [2])
  @RequireProperty('totalFarmAreaUnit')
  totalFarmArea: number

  @IsString()
  @IsEnum(AreaUnit)
  @IsNotEmpty()
  @RequireProperty('totalFarmArea')
  totalFarmAreaUnit: AreaUnit

  @IsNumber()
  @Min(0)
  @Max(100)
  @IsInt()
  percentageProtectionZone: number

  @ArrayNotEmpty()
  @IsArray()
  @IsNumber({}, { each: true })
  certificationsIds: number[]

  @IsObject()
  @ValidateNested()
  @Type(() => SaveGrowerCsrCampaign)
  @IsNotEmpty()
  growerCsrCampaign: SaveGrowerCsrCampaign

  @IsString()
  @IsOptional()
  note?: string

  constructor(growerCsr?: SaveGrowerCsr) {
    if (growerCsr?.id) {
      this.id = growerCsr?.id
    }
    this.growerId = growerCsr?.growerId
    this.levelRegenerativeAgriculture = growerCsr?.levelRegenerativeAgriculture
    this.totalFarmArea = growerCsr?.totalFarmArea
    this.totalFarmAreaUnit = growerCsr?.totalFarmAreaUnit
    this.percentageProtectionZone = growerCsr?.percentageProtectionZone
    this.certificationsIds = growerCsr?.certificationsIds
    this.note = growerCsr?.note ?? null
  }
}

export interface GrowerCsrRawValue {
  id?: number
  growerLevelTraining: LevelsTrainingRegenerative
  totalFarmArea: number
  totalFarmAreaUnit: AreaUnit
  farmCertifications: number[]
  percentageProtectionZone: number
  csrNote?: string
  startingYear?: number
  speciesLastYear?: number
  farmAreaDisturbance?: number
  farmAreaDisturbanceUnit?: AreaUnit
  weeksCovered?: number
  semiNaturalPercent?: number
  pollinatorPlan?: boolean
  spadeTestDone?: boolean
  vessTestDone?: boolean
  humicAssessmentDone?: boolean
  soilAnalysisDone?: boolean
  spadeTestField?: number[]
  vessTestField?: number[]
  humicAssessmentField?: number[]
  soilAnalysisField?: number[]
}

export class GrowerCsrDto {
  id: number
  levelRegenerativeAgriculture: LevelsTrainingRegenerative
  totalFarmArea: number
  totalFarmAreaUnit: AreaUnit
  percentageProtectionZone: number
  note: string
  certificationsIds?: number[]

  constructor(growerCsr?: SaveGrowerCsr) {
    if (growerCsr?.id) {
      this.id = growerCsr?.id
    }
    this.levelRegenerativeAgriculture = growerCsr?.levelRegenerativeAgriculture
    this.totalFarmArea = growerCsr?.totalFarmArea
    this.totalFarmAreaUnit = growerCsr?.totalFarmAreaUnit
    this.percentageProtectionZone = growerCsr?.percentageProtectionZone
    this.note = growerCsr?.note
  }
}

export class GrowerCsrCampaignDto {
  id?: number
  campaignId?: number
  growerCsrId?: number
  firstYearWithBonduelle?: number
  speciesLastYear?: number
  soilAreaLimitingDisturbance?: number
  soilAreaLimitingDisturbanceUnit?: string
  numberWeeksSoilCovered?: number
  percentageSemiNaturalHabitat?: number
  pollinatorDefensePlan?: boolean
  spadeTest?: boolean
  vessTest?: boolean
  humicAssessment?: boolean
  soilAnalysis?: boolean

  constructor(growerCsrCampaign?: SaveGrowerCsrCampaign) {
    if (growerCsrCampaign?.id) {
      this.id = growerCsrCampaign?.id
    }
    this.firstYearWithBonduelle = growerCsrCampaign?.firstYearWithBonduelle ?? null
    this.speciesLastYear = growerCsrCampaign?.speciesLastYear ?? null
    this.soilAreaLimitingDisturbance = growerCsrCampaign?.soilAreaLimitingDisturbance ?? null
    this.soilAreaLimitingDisturbanceUnit = growerCsrCampaign?.soilAreaLimitingDisturbanceUnit ?? null
    this.numberWeeksSoilCovered = growerCsrCampaign?.numberWeeksSoilCovered ?? null
    this.percentageSemiNaturalHabitat = growerCsrCampaign?.percentageSemiNaturalHabitat ?? null
    this.pollinatorDefensePlan = growerCsrCampaign?.pollinatorDefensePlan ?? false
    this.spadeTest = growerCsrCampaign?.spadeTest ?? false
    this.vessTest = growerCsrCampaign?.vessTest ?? false
    this.humicAssessment = growerCsrCampaign?.humicAssessment ?? false
    this.soilAnalysis = growerCsrCampaign?.soilAnalysis ?? false
  }
}
