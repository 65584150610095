import { Injectable } from '@angular/core'
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { catchError, Observable, switchMap, throwError } from 'rxjs'
import { Router } from '@angular/router'
import { ErrorCodes } from '@agroone/entities'
import { AuthService, LoggerService } from '../services'
import { ConnectionError, HttpError } from '../models/errors'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private logger: LoggerService, private router: Router, private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const message: string = error.error?.message ?? error.message
        const rError: HttpError | ConnectionError = navigator.onLine
          ? new HttpError(message)
          : new ConnectionError(message)
        if (rError instanceof HttpError) {
          this.logger.debug('catched error', request.url, location.origin)
          if (this.logger.isLocalEnv() || request.url.startsWith('https://api')) {
            if (error.error.message === ErrorCodes.USER_INACTIVE) {
              this.router.navigate(['/access-denied'])
            }

            if (error.error.message === ErrorCodes.TOKENS_EXPIRED) {
              return this.authService.refreshTokens().pipe(switchMap(() => next.handle(request)))
            }

            if (error.error.message === ErrorCodes.REFRESH_TOKEN_EXPIRED) {
              this.authService.redirectToLogin()
            }

            if (error.error.message === ErrorCodes.MISSING_AUTH) {
              this.authService.redirectToLogin()
            }

            if (error.error.message === ErrorCodes.INVALID_TOKEN) {
              this.authService.redirectToLogin()
            }

            if (error.error.message === ErrorCodes.MISSING_PERMISSION_MODULE_ACCESS) {
              this.authService.redirectToLogin()
            }
          }
          rError.status = error.status
        }
        return throwError(rError)
      })
    )
  }
}
