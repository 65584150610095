export enum UserPermissions {
  // Set to the routes that don't require any permission to be accessed
  NONE = 'None',

  // Modules Access
  CROP_MODULE_ACCESS = 'CropModuleAccess',
  HARVEST_MODULE_ACCESS = 'HarvestModuleAccess', // Also known as Agropilot
  PLANTING_MODULE_ACCESS = 'PlantingModuleAccess',
  USER_MODULE_ACCESS = 'UserModuleAccess',
  MASTERDATA_MODULE_ACCESS = 'MasterdataModuleAccess',

  // Harvest, Also known as Agropilot
  HARVEST_SAMPLING = 'HarvestSampling',
  HARVEST_OFFER = 'HarvestOffer',
  HARVEST_PLANIFICATION = 'HarvestPlanification',
  HARVEST_PCRADIO = 'HarvestPcRadio',
  HARVEST_TRUCKS = 'HarvestTrucks',
  HARVEST_STOCKS = 'HarvestStocks',
  HARVEST_FACTORIES = 'HarvestFactories',
  HARVEST_ADMIN = 'HarvestAdmin',

  // User
  USER_EDIT = 'UserEdit',

  // Grower
  GROWERS_VIEW = 'GrowersView',
  GROWERS_EDIT = 'GrowersEdit',

  // Field
  FIELDS_BATCH_EDIT = 'FieldsBatchEdit',
  FIELDS_EDIT = 'FieldsEdit',
  FIELDS_VIEW = 'FieldsView',

  // Crop
  CROPS_BATCH_EDIT = 'CropsBatchEdit',
  CROPS_EDIT = 'CropsEdit',
  CROPS_VIEW = 'CropsView',
  CROPS_DELETE = 'CropsDelete',
  PLANTING_EDIT = 'PlantingEdit',

  // Scouting - reco - inter
  SCOUTINGS_EDIT = 'ScoutingsEdit',
  RECOMMENDATIONS_EDIT = 'RecommendationsEdit',
  INTERVENTIONS_EDIT = 'InterventionsEdit',
  SCOUTINGS_HISTORIC = 'ScoutingsHistoric',

  // Intervention Disable
  INTERVENTION_DISABLE = 'InterventionsDelete',

  // Geolocation
  GEOLOCATION_VIEW = 'GeolocationView',

  // Dashboard
  DASHBOARD_VIEW = 'DashboardView',
  WORKLIST_VIEW = 'WorklistView',
  WORKLIST_EDIT = 'WorklistEdit',

  // Sample
  SAMPLE_EDIT = 'SampleEdit',
  SAMPLE_VALIDATE = 'SampleValidate',
  SAMPLE_DELETE = 'SampleDelete',

  // TPA
  // THIRD_PARTY_APPLICATOR_VIEW = 'ThirdPartyApplicatorView',
  // THIRD_PARTY_APPLICATOR_TASK_EDIT = 'ThirdPartyApplicatorTaskEdit',

  // Supplier
  SUPPLIER_VIEW = 'SupplierView',
  SUPPLIER_EDIT = 'SupplierEdit',

  MASTERDATA_TRANSLATION = 'MasterdataTranslation',
  MASTERDATA_CONSTANT_EDITION = 'MasterdataConstantEdition',
  MASTERDATA_KEYS_EDITION = 'MasterdataKeysEdition',
  MASTERDATA_HOMOLOGA_VIEWER = 'MasterdataHomologaViewer',
  MASTERDATA_SPECIFICATION_MGT_VIEW = 'MasterdataSpecificationMGTView',

  MASTERDATA_SPECIFICATION_BOARD_VIEW = 'MasterdataSpecificationView',
  MASTERDATA_SPECIFICATION_MANAGER = 'MasterdataSpecificationManager',

  MASTERDATA_VARIETIES_VIEW = 'MasterdataVarietiesView',
  MASTERDATA_VARIETIES_EDIT = 'MasterdataVarietiesEdit',
  MASTERDATA_CSR_EDITION = 'MasterdataCSREdition',
}
