/* eslint-disable no-console */
import { Injectable } from '@angular/core'
import { format } from 'date-fns'
import { MonitoringService } from './monitoring.service'

@Injectable()
export class LoggerService {
  private logLevels: string[] = ['DEBUG', 'INFO', 'LOG', 'WARN', 'ERROR']
  private serverLogLevels: string[] = ['DEBUG', 'INFO', 'LOG', 'WARN', 'ERROR']
  private isLocal: boolean

  constructor(private monitoringService: MonitoringService) {}

  init(logLevel: string, serverLogLevel: string, isLocal: boolean) {
    const logLevelIndex: number = this.logLevels.indexOf(logLevel)
    if (logLevelIndex >= 0) {
      this.logLevels = this.logLevels.splice(logLevelIndex, this.logLevels.length)
    } else {
      this.logLevels = []
    }

    const serverLogLevelIndex: number = this.serverLogLevels.indexOf(serverLogLevel)
    if (serverLogLevelIndex >= 0) {
      this.serverLogLevels = this.serverLogLevels.splice(serverLogLevelIndex, this.serverLogLevels.length)
    } else {
      this.serverLogLevels = []
    }
    this.isLocal = isLocal
  }

  isLocalEnv(): boolean {
    return this.isLocal
  }

  /**
   * Log in console with log level DEBUG
   */
  debug(message: string, ...optionalParams: any[]) {
    this.doLog('DEBUG', message, ...optionalParams)
  }

  /**
   * Log in console with log level INFO
   */
  info(message: string, ...optionalParams: any[]) {
    this.doLog('INFO', message, ...optionalParams)
  }

  /**
   * Log in console with log level LOG
   */
  log(message: string, ...optionalParams: any[]) {
    this.doLog('LOG', message, ...optionalParams)
  }

  /**
   * Log in console with log level WARN
   */
  warn(message: string, ...optionalParams: any[]) {
    this.doLog('WARN', message, ...optionalParams)
  }

  /**
   * Log in console with log level ERROR
   */
  error(message: string, ...optionalParams: any[]) {
    this.doLog('ERROR', message, ...optionalParams)
    this.monitoringService.error(message, optionalParams?.[0])
  }

  private doLog(level: string, message: string, ...optionalParams: any[]) {
    let emoji: string = ''
    let levelColor: string = 'currentColor'
    let messageColor: string = 'currentColor'
    switch (level) {
      case 'DEBUG':
        emoji = String.fromCodePoint(0x1f41e)
        levelColor = 'teal'
        break
      case 'INFO':
        emoji = String.fromCodePoint(0x1f984)
        levelColor = 'blue'
        break
      case 'LOG':
        levelColor = 'gray'
        break
      case 'WARN':
        levelColor = 'darkorange'
        messageColor = 'darkorange'
        break
      case 'ERROR':
        emoji = String.fromCodePoint(0x274c)
        levelColor = 'red'
        messageColor = 'red'
        break
    }

    if (this.logLevels.includes(level)) {
      console.log(
        `%c${format(new Date(), 'HH:mm:ss')}%c ${emoji} [${level}]: %c${message}${optionalParams?.length ? '\n' : ''}`,
        `color:black;
        font-weight: bold;
        background-color: #EEE;
        padding: 0 0.5em;
        border-radius: 1em;`,
        `color:${levelColor};
        font-weight: bold;`,
        `color:${messageColor}`,
        ...optionalParams
      )
    }
  }
}
