import { Injectable } from '@angular/core'
// eslint-disable-next-line no-unused-vars,@typescript-eslint/ban-ts-comment
// @ts-ignore
import { dtrum } from '@dynatrace/dtrum-api-types'

// KEEP THE LINE ABOVE IN ORDER TO MAKE DYNATRACE WORKS

@Injectable()
export class MonitoringService {
  public identifyUser(user: string) {
    if (typeof window.dtrum !== 'undefined' && window.dtrum?.identifyUser) {
      window.dtrum.identifyUser(user)
    }
  }

  /**
   * Log an error
   */
  public error(message: string, error?: Error) {
    if (typeof window.dtrum !== 'undefined' && window.dtrum?.reportError) {
      window.dtrum.reportError(error ?? message)
    }
  }

  /**
   * Begin the monitoring of a custom action.
   * Don't forget to end the action with leaveAction()
   *
   * @returns the ID of the action
   */
  public enterAction(actionName: string): number {
    if (typeof window.dtrum !== 'undefined' && window.dtrum?.enterAction) {
      return window.dtrum.enterAction(actionName)
    }
    return null
  }

  /**
   * End the monitoring of a custom action
   *
   * @param actionId
   */
  public leaveAction(actionId: number) {
    if (Number.isInteger(actionId) && typeof window.dtrum !== 'undefined' && window.dtrum?.leaveAction) {
      window.dtrum.leaveAction(actionId)
    }
  }
}
